<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<router-link :to="{path: '/tasks'}" class="btn btn-link">
				<font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon>
			</router-link>
			<h1 class="h2">Добавление задачи</h1>
			<div class="btn-toolbar mb-2 mb-md-0">
				<button type="button" class="btn btn-primary" @click="onAdd()">
					<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
				</button>
				<!--
				<div class="btn-group me-2">
					<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
					<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
				</div>
				<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
					<span data-feather="calendar"></span>
					This week
				</button>
				-->
			</div>
		</div>
		<b-overlay :show="overlay" rounded="lg" variant="white">
			<div class="row">
				<div class="col-md-6 offset-md-3">
					<div id="input-group-parent-id" role="group" class="form-group">
						<label id="input-group-parent-id" for="input-parent-id" class="d-block">Parent ID задачи:</label>
						<div>
							<input id="input-parent-id" type="number" class="form-control" v-model="parent_id" />
						</div>
					</div>
					<div id="input-group-pos" role="group" class="form-group">
						<label id="input-group-pos" for="input-pos" class="d-block">Позиция задачи:</label>
						<div>
							<input id="input-pos" type="number" class="form-control" v-model="pos" />
						</div>
					</div>
					<div id="input-group-title" role="group" class="form-group">
						<label id="input-group-title" for="input-title" class="d-block">Заголовок задачи:</label>
						<div>
							<input id="input-title" type="text" placeholder="Например: Задача" class="form-control" v-model="title" autofocus />
						</div>
					</div>
					<div id="input-group-description" role="group" class="form-group">
						<label id="input-group-description" for="input-description" class="d-block">Описание задачи:</label>
						<div>
							<textarea id="input-description" rows="4" class="form-control" v-model="description"></textarea>
						</div>
					</div>
					<div id="input-group-solutions" role="group" class="form-group">
						<label id="input-group-solutions" for="input-solutions" class="d-block">Пути решения задачи:</label>
						<div>
							<textarea id="input-solutions" rows="4" class="form-control" v-model="solutions"></textarea>
						</div>
					</div>
					<div id="input-group-result-plan" role="group" class="form-group">
						<label id="input-group-result-plan" for="input-result-plan" class="d-block">Планируемый результат:</label>
						<div>
							<textarea id="input-result-plan" rows="4" class="form-control" v-model="result_plan"></textarea>
						</div>
					</div>
					<div id="input-group-result-fact" role="group" class="form-group">
						<label id="input-group-result-fact" for="input-result-fact" class="d-block">Фактический результат:</label>
						<div>
							<textarea id="input-result-fact" rows="4" class="form-control" v-model="result_fact"></textarea>
						</div>
					</div>
					<div id="input-group-type-id" role="group" class="form-group">
						<label id="input-group-type-id" for="input-type-id" class="d-block">Тип задачи:</label>
						<div>
							<select id="select-type-id" class="form-control" v-model="type_id">
								<option :value="t.id" :key="t.id" v-for="t in types">{{t.name}}</option>
							</select>
						</div>
					</div>
					<div id="input-group-status-id" role="group" class="form-group">
						<label id="input-group-status-id" for="input-status-id" class="d-block">Статус задачи:</label>
						<div>
							<select id="select-status-id" class="form-control" v-model="status_id" :disabled="!type_id">
								<option :value="s.id" :key="s.id" v-for="s in statuses">{{s.name}}</option>
							</select>
						</div>
					</div>
					<div id="input-group-priority-id" role="group" class="form-group">
						<label id="input-group-priority-id" for="input-priority-id" class="d-block">Приоритет задачи:</label>
						<div>
							<select id="select-priority-id" class="form-control" v-model="priority_id">
								<option :value="p.id" :key="p.id" v-for="p in priorities">{{p.weight}}: {{p.name}}</option>
							</select>
						</div>
					</div>
					<div id="input-group-user-assigned-id" role="group" class="form-group">
						<label id="input-group-user-assigned-id" for="input-user-assigned-id" class="d-block">Кому назначена задача:</label>
						<div>
							<input id="input-user-assigned-id" type="number" class="form-control" v-model="user_assigned_id" />
						</div>
					</div>
					<div id="input-group-user-created-id" role="group" class="form-group">
						<label id="input-group-user-created-id" for="input-user-created-id" class="d-block">Кем создана задача:</label>
						<div>
							<input id="input-user-created-id" type="number" class="form-control" v-model="user_created_id" />
						</div>
					</div>
					<div id="input-group-estimate" role="group" class="form-group">
						<label id="input-group-estimate" for="input-estimate" class="d-block">Оценка времени (минут, часов, дней) в минутах:</label>
						<div>
							<input id="input-estimate" type="number" min="0" class="form-control" v-model="estimate" />
						</div>
					</div>
					<div id="input-group-progress" role="group" class="form-group">
						<label id="input-group-progress" for="input-progress" class="d-block">Прогресс выполнения задачи ({{progress}}%):</label>
						<div>
							<input id="input-progress" type="range" min="0" max="100" step="5" class="form-control" v-model="progress" />
						</div>
					</div>
					<div id="input-group-date-start-plan" role="group" class="form-group">
						<label id="input-group-date-start-plan" for="input-date-start-plan" class="d-block">Дата планируемого начала выполнения:</label>
						<div>
							<input id="input-date-start-plan" type="date" class="form-control" v-model="date_start_plan" />
						</div>
					</div>
					<div id="input-group-date-start-fact" role="group" class="form-group">
						<label id="input-group-date-start-fact" for="input-date-start-fact" class="d-block">Дата начала выполнения по факту:</label>
						<div>
							<input id="input-date-start-fact" type="date" class="form-control" v-model="date_start_fact" />
						</div>
					</div>
					<div id="input-group-date-end-plan" role="group" class="form-group">
						<label id="input-group-date-end-plan" for="input-date-end-plan" class="d-block">Дата планируемого завершения выполнения:</label>
						<div>
							<input id="input-date-end-plan" type="date" class="form-control" v-model="date_end_plan" />
						</div>
					</div>
					<div id="input-group-date-end-fact" role="group" class="form-group">
						<label id="input-group-date-end-fact" for="input-date-end-fact" class="d-block">Дата завершения выполнения по факту:</label>
						<div>
							<input id="input-date-end-fact" type="date" class="form-control" v-model="date_end_fact" />
						</div>
					</div>
					<div id="input-group-date-deadline" role="group" class="form-group">
						<label id="input-group-date-deadline" for="input-date-deadline" class="d-block">Дата дедлайна:</label>
						<div>
							<input id="input-date-deadline" type="date" class="form-control" v-model="date_deadline" />
						</div>
					</div>
					
					<div :id="'input-group-custom-field-'+cf.id" role="group" class="form-group" v-for="cf in custom_fields" :key="cf.id">
						<label :id="'input-group-custom-field-'+cf.id" :for="'item-custom-field-'+cf.id" class="d-block">{{cf.name}}:</label>
						<div>ID: {{cf.id}}</div>
						<div>Type: {{cf.type}}</div>
						<div>Data: {{cf.data}}</div>
						<div v-if="cf.type == 'text'">
							<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'textarea'">
							<textarea :id="'item-custom-field-'+cf.id" rows="3" class="form-control" v-model="custom_fields_values_id[cf.id]"></textarea>
						</div>
						<div v-if="cf.type == 'checkbox'">
							<input type="checkbox" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'number'">
							<!-- cf.data.decimal_places -->
							<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'currency'">
							<!-- cf.data.decimal_places -->
							<div class="input-group mb-2">
								<div class="input-group-prepend">
									<div class="input-group-text">{{cf.data.symbol}}</div>
								</div>
								<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
							</div>
						</div>
						<div v-if="cf.type == 'percentage'">
							<!-- data.decimal_places -->
							<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'date'">
							<input type="date" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'dropdown'">
							<select :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]">
								<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
							</select>
						</div>
						<div v-if="cf.type == 'multiselect'">
							<select :id="'item-custom-field-'+cf.id" multiple class="form-control" v-model="custom_fields_values_id[cf.id]">
								<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
							</select>
						</div>
						<div v-if="cf.type == 'rating'">
							
							<input type="range" :id="'item-custom-field-'+cf.id" min="0" :max="cf.data.scale" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'email'">
							<input type="email" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'phone'">
							<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'link'">
							<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
						<div v-if="cf.type == 'user'">
							
							<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values_id[cf.id]" />
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
		<div class="alert alert-info" v-html="result" v-if="result != ''"></div>
	</div>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
import BOverlay from '@/components/bootstrap/overlay.vue';

export default {
	name: 'TaskAdd',
	setup(){
		useMeta({title: 'Добавление задачи'});
	},
	data: () => ({
		overlay: false,
		
		parent_id: '',
		pos: '',
		title: '',
		description: '',
		solutions: '',
		result_plan: '',
		result_fact: '',
		type_id: '',
		status_id: '',
		priority_id: '',
		user_assigned_id: '',
		user_created_id: '',
		estimate: '',
		progress: '',
		date_start_plan: '',
		date_start_fact: '',
		date_end_plan: '',
		date_end_fact: '',
		date_deadline: '',
		
		types: [],
		statuses: [],
		priorities: [],
		custom_fields: [],
		custom_fields_values_id: {},
		
		result: '',
	}),
	computed: {
		token(){
			return this.$store.state.token;
		},
	},
	watch: {
		async type_id(val){
			if(val){
				this.overlay = true;
				this.status_id = '';
				await this.loadStatuses();
				await this.loadCustomFields();
				this.overlay = false;
			}
		},
	},
	methods: {
		sortedByPos(arr){
			return arr.sort((a, b) => {
				return a.pos > b.pos ? 1 : (a.pos < b.pos ? -1 : 0);
			});
		},
		
		async loadTypes(){
			await fetch('/api/tasks-types?' + new URLSearchParams({
				virtualspace_id: 1,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.types = data.list;
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
		async loadStatuses(){
			await fetch('/api/tasks-statuses-by-tasks-type/'+this.type_id+'?' + new URLSearchParams({
				virtualspace_id: 1,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.statuses = data.list;
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
		async loadPriorities(){
			await fetch('/api/tasks-priorities?' + new URLSearchParams({
				virtualspace_id: 1,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.priorities = data.list;
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
		async loadCustomFields(){
			await fetch('/api/tasks-custom-fields-by-tasks-type/'+this.type_id+'?' + new URLSearchParams({
				virtualspace_id: 1,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.custom_fields = data.list.map((cf) => {
					cf.data = JSON.parse(cf.data||'[]');
					return cf;
				});
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
		onAdd(){
			this.result = 'Добавление...';
			this.overlay = true;
			
			let formData = new FormData();
			formData.append('virtualspace_id', 1);
			formData.append('parent_id', this.parent_id);
			formData.append('pos', this.pos);
			formData.append('title', this.title);
			formData.append('description', this.description);
			formData.append('solutions', this.solutions);
			formData.append('result_plan', this.result_plan);
			formData.append('result_fact', this.result_fact);
			formData.append('type_id', this.type_id);
			formData.append('status_id', this.status_id);
			formData.append('priority_id', this.priority_id);
			formData.append('user_assigned_id', this.user_assigned_id);
			formData.append('user_created_id', this.user_created_id);
			formData.append('estimate', this.estimate);
			formData.append('progress', this.progress);
			formData.append('date_start_plan', this.date_start_plan);
			formData.append('date_start_fact', this.date_start_fact);
			formData.append('date_end_plan', this.date_end_plan);
			formData.append('date_end_fact', this.date_end_fact);
			formData.append('date_deadline', this.date_deadline);
			
			fetch('/api/tasks', {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.$router.push('/tasks/'+ret.id);
				} else {
					if(ret.error) this.result = ret.error;
					this.overlay = false;
				}
			}).catch(error => {
				this.result = 'Ошибка! '+error;
				this.overlay = false;
				console.error(error);
			});
		},
	},
	async beforeMount(){
		window.scrollTo(0, 0);
		this.overlay = true;
		await this.loadTypes();
		await this.loadPriorities();
		this.overlay = false;
	},
	async beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.overlay = true;
		await this.loadTypes();
		await this.loadPriorities();
		this.overlay = false;
	},
	components: {
		BOverlay,
	},
}
</script>