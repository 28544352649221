<template>
	<div>
		<navbar></navbar>
		<div class="container-fluid">
			<div class="row">
				<sidebar
					:menu="sidebarMenu"
				></sidebar>
				<main class="col-md-9 ml-sm-auto col-lg-10 px-md-4 mb-4">
					<button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<router-view/>
				</main>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
	name: 'LayoutMain',
	setup(){
		useMeta({title: 'CWT'});
	},
	data: () => ({
		sidebarMenu: [],
	}),
	methods: {
		loadSidebarMenu(){
			this.sidebarMenu = [
				{
					elems: [
						{
							path: '/targets',
							text: 'Цели задач',
						},
						{
							path: '/tasks',
							text: 'Задачи',
						},
					],
				},
				{
					header: 'Настройки',
					elems: [
						{
							path: '/projects',
							text: 'Проекты задач',
						},
						{
							path: '/tags',
							text: 'Теги задач',
						},
						{
							path: '/tasks-types',
							text: 'Типы задач',
						},
						{
							path: '/document-templates',
							text: 'Шаблоны задач',
						},
						{
							path: '/tasks-custom-fields',
							text: 'Дополнительные поля для задач',
						},
						{
							path: '/tasks-priorities',
							text: 'Приоритеты задач',
						},
						{
							path: '/tasks-statuses-types',
							text: 'Типы статусов задач',
						},
						{
							path: '/tasks-statuses',
							text: 'Статусы задач',
						},
					],
				},
			];
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
		this.loadSidebarMenu();
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.loadSidebarMenu();
	},
	computed: {},
	components: {
		Navbar,
		Sidebar,
	},
}
</script>