<template>
	<div style="margin-top: 50px; text-align: center;">
		<div style="font-size: 200px; font-weight: bold;">404</div>
		<div style="font-size: 30px;">Страница не существует</div>
		<router-link :to="{path: '/'}" class="btn btn-lg btn-primary mt-5">На главную</router-link>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
export default {
	name: 'PageNotFound',
};
</script>