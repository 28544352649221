<template>
	<nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
		<div class="position-sticky pt-3">
			<div v-for="groupitem in menu">
				<hr v-if="groupitem.divider" />
				<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted" v-if="groupitem.header">
					<span>{{groupitem.header}}</span>
					<!--
					<a class="link-secondary" href="#" aria-label="Add a new report">
						<i class="fa fa-plus-square-o fa-lg"></i>
					</a>
					-->
				</h6>
				<ul class="nav flex-column mb-4">
					<li class="nav-item" v-for="menuitem in groupitem.elems">
						<router-link custom :to="{path: menuitem.path}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<a :href="href" @click="navigate" :class="['nav-link', {'active': isExactActive}]">
								<i v-if="menuitem.icon" :class="[menuitem.icon, 'me-2']"></i>
								<span>{{menuitem.text}}</span>
							</a>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<style lang="css" scoped>

</style>

<script>
export default {
	name: 'Sidebar',
	props: {
		menu: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		
	}),
	methods: {},
	computed: {},
}
</script>