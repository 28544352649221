<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<router-link :to="{path: '/tasks-priorities'}" class="btn btn-link">
				<font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon>
			</router-link>
			<h1 class="h2">Добавление приоритета задач</h1>
			<div class="btn-toolbar mb-2 mb-md-0">
				<button type="button" class="btn btn-primary" @click="onAdd()">
					<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
				</button>
				<!--
				<div class="btn-group me-2">
					<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
					<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
				</div>
				<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
					<span data-feather="calendar"></span>
					This week
				</button>
				-->
			</div>
		</div>
		<b-overlay :show="overlay" rounded="lg" variant="white">
			<div class="row">
				<div class="col-md-6 offset-md-3">
					<div id="input-group-name" role="group" class="form-group">
						<label id="input-group-name" for="input-name" class="d-block">Название приоритета задач:</label>
						<div>
							<input id="input-name" type="text" placeholder="Например: Нормальный" class="form-control" v-model="name" autofocus />
						</div>
					</div>
					<div id="input-group-weight" role="group" class="form-group">
						<label id="input-group-weight" for="input-weight" class="d-block">Вес приоритета задач:</label>
						<div>
							<input id="input-weight" type="number" max="99" class="form-control" v-model="weight" />
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
		<div class="alert alert-info" v-html="result" v-if="result != ''"></div>
	</div>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
import BOverlay from '@/components/bootstrap/overlay.vue';

export default {
	name: 'ProjectAdd',
	setup(){
		useMeta({title: 'Добавление приоритета задач'});
	},
	data: () => ({
		overlay: false,
		
		name: '',
		weight: 0,
		
		result: '',
	}),
	computed: {
		token(){
			return this.$store.state.token;
		},
	},
	methods: {
		onAdd(){
			this.result = 'Добавление...';
			this.overlay = true;
			
			let formData = new FormData();
			formData.append('virtualspace_id', 1);
			formData.append('name', this.name);
			formData.append('weight', this.weight);
			
			fetch('/api/tasks-priorities', {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.$router.push('/tasks-priorities/'+ret.id);
				} else {
					if(ret.error) this.result = ret.error;
					this.overlay = false;
				}
			}).catch(error => {
				this.result = 'Ошибка! '+error;
				this.overlay = false;
				console.error(error);
			});
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		BOverlay,
	},
}
</script>