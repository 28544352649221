<template>
	<nav class="navbar navbar-light bg-light sticky-top flex-md-nowrap p-0 shadow">
		<router-link :to="{path: '/'}" class="navbar-brand col-md-3 col-lg-2 mr-0 px-3 mb-0 h1 text-center text-white">CALC WORK TIME</router-link>
		<button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<!--<input class="form-control w-100" type="text" placeholder="Search" aria-label="Search" />-->
		<ul class="navbar-nav px-3">
			<li class="nav-item text-nowrap">
				<router-link :to="{path: '/logout'}" class="nav-link">Выход</router-link>
			</li>
		</ul>
	</nav>
</template>

<style lang="css" scoped>

</style>

<script>
export default {
	name: 'Navbar',
	props: {},
	data: () => ({
		
	}),
	methods: {},
	computed: {},
}
</script>