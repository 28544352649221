import '@/main.scss';

import { createApp } from 'vue';
import store from '@/store/store';
import App from '@/App.vue';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import PrimeVue from 'primevue/config';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@/assets/css/dashboard.css';

import '@/assets/js/date.format.js';

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(metaManager);
app.use(Toaster);
app.use(PrimeVue);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

import lib from '@/lib';
window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);
