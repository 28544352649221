<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<div></div>
			<h1 class="h2">Cтатусы задач</h1>
			<div class="btn-toolbar mb-2 mb-md-0">
				<router-link :to="{path: '/tasks-statuses/add'}" class="btn btn-primary">Добавить</router-link>
				<!--
				<div class="btn-group me-2">
					<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
					<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
				</div>
				<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
					<span data-feather="calendar"></span>
					This week
				</button>
				-->
			</div>
		</div>
		<loading-spinner
			:overlay="overlay"
		></loading-spinner>
		<div class="row" v-if="list.length > 0 && !overlay">
			<div class="col-md-6 offset-md-3">
				<div class="list-group">
					<router-link custom :to="{path: '/tasks-statuses/'+item.id}" v-slot="{href, route, navigate, isActive, isExactActive}" v-for="item in list">
						<a :href="href" @click="navigate" class="list-group-item list-group-item-action">{{item.name}}</a>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
	name: 'TasksStatusesList',
	setup(){
		useMeta({title: 'Статусы задач'});
	},
	data: () => ({
		overlay: true,
		
		searchText: '',
		
		list: [],
		count: [],
	}),
	methods: {
		async loadData(){
			await fetch('/api/tasks-statuses?' + new URLSearchParams({
				virtualspace_id: 1,
				search: this.searchText,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.list = data.list;
				this.count = data.count;
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
	},
	async beforeMount(){
		window.scrollTo(0, 0);
		this.overlay = true;
		await this.loadData();
		this.overlay = false;
	},
	async beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.overlay = true;
		await this.loadData();
		this.overlay = false;
	},
	computed: {},
	components: {
		LoadingSpinner,
	},
}
</script>